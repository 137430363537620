import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
} from '@mui/material';
import { useSelector } from 'react-redux';
import DashboardHeader from './header';
import { useTheme } from '@emotion/react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
const PublicProfilePage = () => {
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const { username } = useParams();
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [userLinks, setUserLinks] = useState([]);
  const [showMoreLinks, setShowMoreLinks] = useState(false);
  const [following, setFollowing] = useState(false);
  const [playingVideos, setPlayingVideos] = useState({});
  const videoRefs = useRef([]);
  const observerRefs = useRef([]);
  const theme = useTheme();
  const togglePlayPause = (index) => {
    const video = videoRefs.current[index];
    if (playingVideos[index]) {
      video.pause();
    } else {
      video.play();
    }
    setPlayingVideos((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  useEffect(() => {
    if (username) {
      fetchUserData(username);
      fetchPosts(username);
    }
  }, [username]);

  const fetchUserData = async (username) => {
    try {
      const [followersRes, userRes] = await Promise.all([
        axios.get(`https://soapboxapi.megahoot.net/user/followers/${username}`),
        axios.get(`https://soapboxapi.megahoot.net/user/${username}`),
      ]);

      const isFollowing = followersRes.data.some(
        (e) => e.followedBy === 'currentLoggedInUser'
      );
      setFollowing(isFollowing);

      if (userRes.data.length > 0) {
        const userData = userRes.data[0];
        setUser(userData);

        const links = Object.entries(userData).reduce((acc, [key, value]) => {
          if (
            typeof value === 'string' &&
            (value.startsWith('http') ||
              value.startsWith('www') ||
              value.endsWith('.com')) &&
            key !== 'email'
          ) {
            acc.push({ label: key, link: value });
          }
          return acc;
        }, []);
        setUserLinks(links);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPosts = (username, page, view) => {
    axios
      .get(
        `https://soapboxapi.megahoot.net/upload/user/public/p/${username}?page=${page}&limit=30`
      )
      .then((res) => {
        if (res.data && res.data.results && res.data.results.length > 0) {
          if (posts && posts.length > 0) {
            const existingData = [...posts, ...res.data.results];
            setPosts(existingData);
            // setCachedPosts(existingData);
          } else {
            setPosts(res.data.results);
          }
        }
        // else {
        //   setHasMore(false);
        // }
      });
  };

  const handleFollow = async () => {
    try {
      await axios.post(`https://soapboxapi.megahoot.net/user/follow`, {
        username,
      });
      setFollowing(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnfollow = async () => {
    try {
      await axios.post(`https://soapboxapi.megahoot.net/user/unfollow`, {
        username,
      });
      setFollowing(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <DashboardHeader
        image={`https://soapboxapi.megahoot.net/profile-pictures/${user?.profilePic}`}
        sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1200 }} // Ensure header is on top
      />
      {user && (
        <div style={{ maxWidth: '1100px', marginTop: '100px' }}>
          {/* <h1>{user.name}</h1> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={`https://soapboxapi.megahoot.net/profile-pictures/${user.profilePic}`}
              alt={user.username}
              style={{ width: 100, height: 100, borderRadius: '50%' }}
            />
            <div style={{ marginLeft: 20 }}>
              <Typography variant="body1">@{user.username}</Typography>
              <Typography variant="h6">{user.name}</Typography>
              <Typography variant="body2">{user.bio}</Typography>
              {following ? (
                <Button onClick={handleUnfollow} variant="outlined">
                  Unfollow
                </Button>
              ) : (
                <Button onClick={handleFollow} variant="contained">
                  Follow
                </Button>
              )}
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <h2>Links</h2>
            <List>
              {userLinks
                .slice(0, showMoreLinks ? userLinks.length : 3)
                .map((link) => (
                  <ListItem
                    button
                    component="a"
                    href={link.link}
                    target="_blank"
                    key={link.link}
                  >
                    <ListItemText primary={link.label} secondary={link.link} />
                  </ListItem>
                ))}
              {userLinks.length > 3 && (
                <Button onClick={() => setShowMoreLinks(!showMoreLinks)}>
                  {showMoreLinks
                    ? 'Show Less'
                    : `Show More (${userLinks.length - 3})`}
                </Button>
              )}
            </List>
          </div>

          <div style={{ marginTop: 20, marginBottom: '100px' }}>
            <h2>Posts</h2>
            <Grid container spacing={2}>
              {posts.map((post, index) => (
                <Grid item xs={12} sm={6} md={3} key={post.id}>
                  <Card
                    style={{
                      margin: 'auto',
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    <CardMedia
                      component={
                        post.mimeType?.includes('video') ? 'video' : 'img'
                      }
                      ref={(el) => (videoRefs.current[index] = el)}
                      src={`https://soapboxapi.megahoot.net/images/${post.image}`}
                      controls={false}
                      alt={post.caption}
                      sx={{
                        objectFit: 'cover',
                        height: 380,

                        width: '100%',
                      }}
                      // poster={`https://soapboxapi.megahoot.net/images/${post.audioPoster}`}
                      draggable={false}
                    />
                    {post.mimeType?.includes('video') && (
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: '45%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: theme.palette.primary.main,
                          zIndex: 2,
                        }}
                        onClick={() => togglePlayPause(index)}
                      >
                        {playingVideos[index] ? (
                          <PauseIcon sx={{ fontSize: 40, color: '#fff' }} />
                        ) : (
                          <PlayArrowIcon sx={{ fontSize: 40, color: '#fff' }} />
                        )}
                      </IconButton>
                    )}

                    <CardContent>
                      <Typography variant="body2" color="textSecondary">
                        {post.caption}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default PublicProfilePage;
