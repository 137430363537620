import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  Paper,
  TextField,
  IconButton,
  Chip,
} from '@mui/material';
import {
  MarkEmailUnreadOutlined,
  EditNoteOutlined,
  SendOutlined,
  Close,
} from '@mui/icons-material';

function Email({ emailData, setEmailView }) {
  const theme = useTheme();
  const [selectedEmail, setSelectedEmail] = useState(null); // State for selected email
  const [composeMode, setComposeMode] = useState(false); // State to toggle between viewing and composing

  const handleSelectEmail = (email) => {
    setSelectedEmail(email);
    setComposeMode(false); // Disable compose mode when an email is selected
  };

  const handleComposeClick = () => {
    setComposeMode(true); // Enable compose mode when the compose icon is clicked
    setSelectedEmail(null); // Deselect the currently selected email
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: 2,
        position: 'relative',
        minHeight: '91vh',
        maxHeight: '91vh',
      }}
    >
      {/* Email List (30% of the width) */}
      <Button
        color="error"
        variant="contained"
        sx={{
          position: 'absolute',
          right: '41px',
          top: '32px',
          height: '48px',
        }}
        onClick={() => {
          setEmailView(false);
        }}
      >
        <Close />
      </Button>
      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        component={Paper}
        elevation={3}
        sx={{ padding: 2, maxHeight: '91vh', overflow: 'auto' }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: 3,
            bgcolor: theme.palette.primary.main,
            textAlign: 'center',
            padding: 1,
            color: theme.palette.primary.contrastText,
          }}
        >
          Mega Mail
        </Typography>

        <List>
          {emailData?.map((email) => (
            <ListItem
              button
              key={email.id}
              onClick={() => handleSelectEmail(email)}
              sx={{
                borderRadius: 2,
                mb: 1,
                padding: '10px 15px',
                bgcolor:
                  selectedEmail?.id === email.id
                    ? theme.palette.action.selected
                    : 'inherit',
              }}
            >
              <Avatar
                src={`https://soapboxapi.megahoot.net/profile-pictures/${email?.user_profilePic}`}
                sx={{ marginRight: 2 }}
              >
                {email.name?.slice(0, 1)}
              </Avatar>
              <ListItemText
                primary={email.user_name}
                secondary={`${email.message?.slice(0, 20)}...`}
                primaryTypographyProps={{ fontWeight: 500 }}
              />
              <Stack direction="row" spacing={1}>
                <IconButton
                  color="primary"
                  onClick={() => console.log('View Email')}
                >
                  <MarkEmailUnreadOutlined />
                </IconButton>
                <IconButton color="secondary" onClick={handleComposeClick}>
                  <EditNoteOutlined />
                </IconButton>
              </Stack>
            </ListItem>
          ))}
        </List>
      </Grid>

      {/* Email View or Compose Section (70% of the width) */}
      <Grid
        item
        xs={12}
        sm={8}
        md={9}
        component={Paper}
        elevation={3}
        sx={{ padding: 3 }}
      >
        {!composeMode && selectedEmail ? (
          <div>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                mb: 3,
                bgcolor: theme.palette.primary.main,
                textAlign: 'center',
                padding: 1,
                color: theme.palette.primary.contrastText,
              }}
            >
              {selectedEmail.subject || 'No Subject'}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                mb: 1,
                // border: '0.5px solid grey',
                bgcolor: '#f0f0f0',
                padding: 1,
              }}
            >
              From: {selectedEmail.user_name}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                mb: 4,
                // border: '0.5px solid grey',
                bgcolor: '#f0f0f0',
                padding: 1,
              }}
            >
              Subject: {selectedEmail.subject}
            </Typography>

            <Typography lineHeight={2.5} variant="body1">
              {selectedEmail.message}
            </Typography>
          </div>
        ) : composeMode ? (
          <div>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Compose New Email
            </Typography>
            <Stack spacing={3}>
              <TextField label="To" variant="outlined" fullWidth />
              <TextField label="Subject" variant="outlined" fullWidth />
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<SendOutlined />}
              >
                Send
              </Button>
            </Stack>
          </div>
        ) : (
          <Typography variant="h6" sx={{ textAlign: 'center', mt: 5 }}>
            Select an email to view or click "Compose" to write a new email.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default Email;
