import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import { useTheme } from '@emotion/react';
import { Avatar } from '@mui/material';

const apps = [
  {
    name: 'The Pecu Wallet: Crypto & Defi',
    image: '/pecu.png',
    provider: 'MegaHoot Technologies',
    link: 'PECU_PinScreen',
  },
  {
    name: 'HootDex : Crypto Swapping',
    image: '/hd.webp',
    provider: 'MegaHoot Technologies',
    link: 'HD_Screen',
  },
];

const MiniProgramsCatalogue = ({ navigation, shareMiniApp }) => {
  const theme = useTheme();
  const renderItem = (item) => (
    <Grid item xs={12} md={6}>
      <Card
        sx={{
          backgroundColor: 'white',
          padding: 2,
          borderRadius: 2,
          marginBottom: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Avatar
          src={item.image}
          alt={item.name}
          sx={{ ml: 1, height: '100px', width: '100px' }}
        />
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            sx={{ color: 'black', marginBottom: 1 }}
          >
            {item.name}
          </Typography>
          <Typography variant="body2" color="#03875f">
            {item.provider}
          </Typography>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                sx={{ backgroundColor: '#03875f' }}
                // onClick={() => {
                //   navigation.navigate(item.link);
                // }}
              >
                Open
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ color: '#03875f', borderColor: '#03875f' }}
                // onClick={() => shareMiniApp(item.name)}
                endIcon={<ShareIcon />}
              >
                Share
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Grid spacing={2} sx={{ padding: 2 }}>
      <Typography
        variant="h5"
        sx={{
          mt: 2,
          mb: 5,
          bgcolor: '#f0f0f0',
          textAlign: 'center',
          padding: 1,
        }}
        color={theme.palette.primary.main}
      >
        Mini Programs
      </Typography>
      <Grid container spacing={2}>
        {apps.map((app) => renderItem(app))}
      </Grid>
    </Grid>
  );
};

export default MiniProgramsCatalogue;
