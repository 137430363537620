import React, { useState } from 'react';
import {
  Card,
  CardContent,
  List,
  ListItem,
  Avatar,
  TextField,
  BottomNavigationAction,
  Paper,
  Grid,
  ListItemText,
  Box,
} from '@mui/material';
import {
  Home,
  Search,
  AddCircle,
  OndemandVideoOutlined,
  EmailOutlined,
  WalletOutlined,
  GroupOutlined,
  SpeakerGroup,
  Apps,
  SpeakerGroupOutlined,
  AppsOutlined,
  AddCircleOutline,
  SearchOutlined,
  HomeOutlined,
  Notifications,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import DashboardHeader from './header';

function LeftPanel({
  chatListData,
  setSelectedChat,
  selectedChat,
  setPage,
  setMessages,
  tabValue,
  setTabValue,
  handleHeaderTabClick,
}) {
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.userInfo);

  return (
    <Grid>
      <Card
        style={{
          height: 'calc(100vh - 64px)',
          borderRadius: 0,
          backgroundColor: '#f5f5f5',
          display: 'flex',
          flexDirection: 'row', // Align items horizontally
        }}
      >
        {/* Chat List Content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Align navigation items vertically
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            padding: '16px 0',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <BottomNavigationAction
            label="Home"
            showLabel={true}
            icon={<HomeOutlined />}
            onClick={() => setTabValue(0)}
            selected={tabValue === 0}
          />
          <BottomNavigationAction
            label="Post"
            showLabel={true}
            icon={<AddCircle sx={{ fontSize: 40 }} color="primary" />}
            onClick={() => handleHeaderTabClick('CREATEPOST')}
            selected={tabValue === 2}
          />
          <BottomNavigationAction
            label="Discover"
            showLabel={true}
            icon={<SearchOutlined />}
            onClick={() => setTabValue(1)}
            selected={tabValue === 1}
          />

          <BottomNavigationAction
            label="Soapbox"
            showLabel={true}
            icon={<OndemandVideoOutlined />}
            onClick={() => setTabValue(3)}
            selected={tabValue === 3}
          />
          <BottomNavigationAction
            label="Mega Mail"
            showLabel={true}
            icon={<EmailOutlined />}
            onClick={() => handleHeaderTabClick('EMAIL')}
          />
          <BottomNavigationAction
            label="Wallet"
            showLabel={true}
            icon={<WalletOutlined />}
            onClick={() => handleHeaderTabClick('WALLET')}
          />
          <BottomNavigationAction
            label="Channels"
            showLabel={true}
            icon={<GroupOutlined />}
            onClick={() => handleHeaderTabClick('CHANNELS')}
          />
          <BottomNavigationAction
            label="Clubs"
            showLabel={true}
            icon={<SpeakerGroupOutlined />}
            onClick={() => handleHeaderTabClick('CHANNELS')}
          />
          <BottomNavigationAction
            label="Mini Apps"
            showLabel={true}
            icon={<AppsOutlined />}
            onClick={() => handleHeaderTabClick('MINIPROGRAMS')}
          />
          <BottomNavigationAction
            label="Notifications"
            showLabel={true}
            icon={<Notifications />}
            onClick={() => handleHeaderTabClick('NOTIFICATIONS')}
          />
          <BottomNavigationAction
            label="Me"
            icon={
              <Avatar
                src={`https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`}
                style={{
                  backgroundColor: theme.palette.primary.light,
                  width: '30px',
                  height: '30px',
                }}
              />
            }
            onClick={() => setTabValue(4)}
            selected={tabValue === 4}
          />
        </Box>
        <CardContent
          style={{
            paddingBottom: 16,
            flexGrow: 1, // This will make the list grow to fill the available space
            overflowY: 'auto', // Allows scrolling if the chat list is too long
          }}
        >
          {/* Search Box */}
          <TextField fullWidth placeholder="Search" />

          {/* Chat List */}
          <List>
            {chatListData?.map((chatRoom) => (
              <ListItem
                button
                key={chatRoom.id}
                onClick={() => {
                  setSelectedChat(chatRoom);
                  setMessages(null);
                  setPage(1);
                  setTabValue(0);
                }}
                selected={selectedChat?.id === chatRoom.id}
                style={{
                  borderRadius: 8,
                  marginBottom: 8,
                  padding: '10px 15px',
                  cursor: 'pointer',
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    marginRight: 12,
                  }}
                  src={`https://soapboxapi.megahoot.net/profile-pictures/${chatRoom?.profilePic}`}
                >
                  {chatRoom.name?.slice(0, 1) ||
                    chatRoom.username?.slice(0, 1) ||
                    chatRoom.chatFrom?.slice(0, 1)}
                </Avatar>
                <ListItemText
                  primary={
                    chatRoom.name || chatRoom.username || chatRoom.chatFrom
                  }
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>

        {/* Vertical Navigation */}
      </Card>
    </Grid>
  );
}

export default LeftPanel;
