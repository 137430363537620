import React, { useRef, useState } from 'react';
import {
  Dialog,
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Paper,
  Avatar,
} from '@mui/material';
import { CloudUpload, Cancel, CameraAlt, Mic } from '@mui/icons-material';
import Webcam from 'react-webcam';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function CreatePostView({
  enablePostView,
  setEnablePostView,
  handleCreatePost,
}) {
  const { userInfo, fcmToken } = useSelector((state) => state.userInfo);
  const [caption, setCaption] = useState('');
  const [hashtags, setHashtags] = useState('');
  const [mediaPreview, setMediaPreview] = useState(null);
  const [mediaFile, setMediaFile] = useState(null);
  const [postVisibility, setPostVisibility] = useState('public'); // Public or Private
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaInputRef = React.createRef();
  const [enableCamera, setEnableCamera] = useState(false);
  const webcamRef = useRef(null);
  const [mediaType, setMediaType] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const [file, setFile] = useState([]);
  const [audioPoster, setAudioPoster] = useState('');
  const [src, setSrc] = useState(null);
  const [mimeType, setMimeType] = useState('');

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [ephemeralCheck, setEphemeralCheck] = useState(false);
  const [privateCheck, setPrivateCheck] = useState(false);
  const [link, setLink] = useState('');
  const [showLinkPreview, setShowLinkPreview] = useState(false);
  // const [links, setLinks] = useState([{ link: "" }]);
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const BaseURL = process.env.REACT_APP_API_URL;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMediaFile(file);
      setMediaPreview(URL.createObjectURL(file));
    }
  };

  const handleCaptureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setMediaPreview(imageSrc);
    setMediaFile(new Blob([imageSrc], { type: 'image/jpeg' })); // Placeholder for image file
    setMediaType('image');
    setEnableCamera(false);
  };

  const handleCapturePhoto = () => {
    setEnableCamera(true);

    // Placeholder for capturing photo functionality const imageSrc = webcamRef.current.getScreenshot();
  };

  const handleCaptureVideo = () => {
    // Placeholder for capturing video functionality
    // alert('Camera functionality not implemented.');
  };

  const handleAudioRecording = async () => {
    return;
    if (isRecording) {
      // Stop recording
      // Assuming you have implemented the logic to capture audio and set the audioBlob
      // This is just a placeholder
      setIsRecording(false);
      setAudioBlob(new Blob()); // Replace with actual audio data
      setMediaPreview(URL.createObjectURL(audioBlob)); // Preview the recorded audio
    } else {
      // Start recording
      setIsRecording(true);
      // Implement start recording logic here
      //  alert('Audio recording started.'); // Placeholder
    }
  };

  // const handleSubmit = () => {
  //   if (!mediaFile && !audioBlob) {
  //     alert('Please upload media.');
  //     return;
  //   }
  //   if (!caption) {
  //     alert('Please enter a caption.');
  //     return;
  //   }

  //   const postData = {
  //     mediaFile: mediaFile || audioBlob,
  //     caption,
  //     hashtags: hashtags.split(' ').filter(Boolean),
  //     visibility: postVisibility,
  //   };

  //   handleCreatePost(postData);
  //   setEnablePostView(false);
  // };

  const handleSubmit = () => {
    setLoading(true);

    const currentDate = new Date();
    const expiryDate = new Date(
      currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    const timeStamp = currentDate.toUTCString();
    const formData = new FormData();
    formData.append('timeStamp', timeStamp);
    formData.append('caption', caption);
    // formData.append("link", JSON.stringify(formValues))
    formData.append('link', '');
    formData.append('ephemeral', ephemeralCheck ? 1 : 0);
    formData.append('private', postVisibility === 'private');
    formData.append('expiryDate', ephemeralCheck ? expiryDate : 0);
    formData.append('authorEmail', userInfo.email);
    formData.append('onDemandMedia', 0);
    formData.append('file', mediaFile);
    formData.append('audioPoster', audioPoster);
    formData.append('fontFamilyStyle', '');
    formData.append('isSensitive', '');
    const uploadData = async () => {
      await axios.post(`${BaseURL}/upload/create`, formData);

      setEnablePostView(false);
      setCaption('');
      setMediaPreview('');
      setMediaFile('');
    };

    const uploadDataToast = uploadData();
    toast.promise(uploadDataToast, {
      pending: 'Sending Hoot...',
      success: 'Hoot Successful',
      error: 'Please try again',
    });
  };

  return (
    <Dialog
      maxWidth={'lg'}
      open={enablePostView}
      fullWidth={true}
      onClose={() => setEnablePostView(false)}
      PaperProps={{
        style: {
          backgroundColor: 'white',
        },
      }}
    >
      <Box sx={{ display: 'flex', height: '80vh' }}>
        {/* Left side: Media Preview */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: '1px solid #ccc',
            backgroundColor: '#fafafa',
            position: 'relative',
          }}
        >
          {enableCamera && (
            <Webcam
              audio={true}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                facingMode: 'user',
              }}
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '100%',
              }}
            />
          )}
          {mediaPreview ? (
            <>
              {/* Dynamically render preview based on media type */}
              {mediaFile?.type.startsWith('image/') && (
                <img
                  src={mediaPreview}
                  alt="Uploaded"
                  style={{ width: '100%', height: 'auto', maxHeight: '100%' }}
                />
              )}
              {mediaFile?.type.startsWith('video/') && (
                <video
                  src={mediaPreview}
                  controls
                  style={{ width: '100%', maxHeight: '100%' }}
                />
              )}
              {mediaFile?.type.startsWith('audio/') && (
                <audio controls src={mediaPreview} style={{ width: '100%' }} />
              )}
              {audioBlob && (
                <audio controls src={mediaPreview} style={{ width: '100%' }} />
              )}
              <IconButton
                sx={{ position: 'absolute', top: 10, right: 10 }}
                onClick={() => {
                  setMediaPreview(null);
                  setMediaFile(null);
                  setAudioBlob(null); // Reset audio blob on cancel
                }}
              >
                <Cancel sx={{ color: 'red' }} />
              </IconButton>
            </>
          ) : (
            !enableCamera && (
              <Button
                variant="contained"
                component="label"
                startIcon={<CloudUpload />}
              >
                Attach Media
                <input
                  hidden
                  accept="image/*, video/*, audio/*"
                  type="file"
                  ref={mediaInputRef}
                  onChange={handleFileChange}
                />
              </Button>
            )
          )}
          <Box sx={{ position: 'absolute', bottom: 20, left: 20 }}>
            <Button
              variant="contained"
              startIcon={<CameraAlt />}
              onClick={enableCamera ? handleCaptureImage : handleCapturePhoto}
            >
              {enableCamera ? 'Capture' : ' Take Photo'}
            </Button>
            <Button
              variant="contained"
              startIcon={<CameraAlt />}
              sx={{ marginLeft: 1 }}
              onClick={handleCaptureVideo}
            >
              Record Video
            </Button>
            <Button
              variant="contained"
              startIcon={<Mic />}
              sx={{ marginLeft: 1 }}
              onClick={handleAudioRecording}
            >
              {isRecording ? 'Stop Recording' : 'Record Audio'}
            </Button>
          </Box>
        </Box>

        {/* Right side: Caption, Hashtags, and Submit */}
        <Box
          sx={{
            flex: 1,
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography variant="h6">Create New Post</Typography>

          {/* Caption Input */}
          <TextField
            label="Caption"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />

          {/* Hashtags Input */}
          <TextField
            label="Hashtags"
            fullWidth
            variant="outlined"
            value={hashtags}
            onChange={(e) => setHashtags(e.target.value)}
            helperText="Separate hashtags with spaces (e.g., #fun #nature)"
          />

          {/* Post Visibility Options */}
          <FormLabel component="legend">Post Visibility</FormLabel>
          <RadioGroup
            row
            value={postVisibility}
            onChange={(e) => setPostVisibility(e.target.value)}
          >
            <FormControlLabel
              value="public"
              control={<Radio />}
              label="Public"
            />
            <FormControlLabel
              value="private"
              control={<Radio />}
              label="Private"
            />
          </RadioGroup>

          {/* Submit and Cancel Buttons */}
          <Grid container spacing={2} sx={{ marginTop: 'auto' }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                Post
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => setEnablePostView(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
}

export default CreatePostView;
