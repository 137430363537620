import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function MessageCard({ message }) {
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.userInfo);

  if (
    message.text.includes('.gif') ||
    message.text.includes('.png') ||
    message.text.includes('.jpg') ||
    message.text.includes('.jpeg') ||
    message.text.includes('.eps')
  ) {
    return (
      <div
        draggable={false}
        style={{
          background:
            message.user._id === userInfo.username
              ? theme.palette.primary.light
              : '#e6e6e6',
          padding: '10px 15px',
          borderRadius: '20px',
          wordBreak: 'break-word',
        }}
      >
        <Typography
          variant="body2"
          style={{
            fontWeight: 500,
            color: message.user._id === userInfo.username ? '#fff' : '#000',
          }}
        >
          <img
            src={message.text?.replace('http://', 'https://')}
            style={{
              maxWidth: '300px',
              objectFit: 'contain',
              borderRadius: '8px',
            }}
            draggable={false}
            alt="mchathive"
          />
        </Typography>
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginTop: 5,
            textAlign: 'right',
            color: message.user._id === userInfo.username ? '#f0f0f0' : '#000',
          }}
        >
          {new Date(message.createdAt).toLocaleTimeString()}
        </Typography>
      </div>
    );
  }
  if (message.text.includes('.mp3') || message.text.includes('.wav')) {
    return (
      <div
        draggable={false}
        style={{
          background: '#f0f0f0',
          padding: '10px 15px',
          borderRadius: '20px',
          wordBreak: 'break-word',
        }}
      >
        <Typography
          variant="body2"
          style={{
            fontWeight: 500,
            color: message.user._id === userInfo.username ? '#fff' : '#000',
          }}
        >
          <video
            src={message.text?.replace('http://', 'https://')}
            style={{ width: '300px', height: '100px' }}
            alt="mchathive"
            controls={true}
          />
        </Typography>
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginTop: 5,
            textAlign: 'right',
            color: '#000',
          }}
        >
          {new Date(message.createdAt).toLocaleTimeString()}
        </Typography>
      </div>
    );
  }

  if (
    message.text.includes('.mp4') ||
    message.text.includes('.mov') ||
    message.text.includes('.wmv') ||
    message.text.includes('.webm') ||
    message.text.includes('.avi')
  ) {
    return (
      <div
        style={{
          background: '#f0f0f0',
          padding: '10px 15px',
          borderRadius: '20px',
          wordBreak: 'break-word',
        }}
      >
        <Typography
          variant="body2"
          style={{
            fontWeight: 500,
            color: message.user._id === userInfo.username ? '#fff' : '#000',
          }}
        >
          <video
            draggable={false}
            src={message.text?.replace('http://', 'https://')}
            style={{ width: '300px', height: 'auto', objectFit: 'contain' }}
            alt="mchathive"
            controls={true}
          />
        </Typography>
        <Typography
          variant="caption"
          style={{
            display: 'block',
            marginTop: 5,
            textAlign: 'right',
            color: '#000',
          }}
        >
          {new Date(message.createdAt).toLocaleTimeString()}
        </Typography>
      </div>
    );
  }
  return (
    <div
      style={{
        maxWidth: '70%',
        background:
          message.user._id === userInfo.username
            ? theme.palette.primary.light
            : '#e6e6e6',
        padding: '10px 15px',
        borderRadius: '20px',
        wordBreak: 'break-word',
      }}
      draggable={false}
    >
      <Typography
        variant="body2"
        style={{
          fontWeight: 500,
          color: message.user._id === userInfo.username ? '#fff' : '#000',
        }}
      >
        {message.text}
      </Typography>
      <Typography
        variant="caption"
        style={{
          display: 'block',
          marginTop: 5,
          textAlign: 'right',
          color: message.user._id === userInfo.username ? '#f0f0f0' : '#000',
        }}
      >
        {new Date(message.createdAt).toLocaleTimeString()}
      </Typography>
    </div>
  );
}

export default MessageCard;
