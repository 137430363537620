import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './screens/login';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import SignUp from './screens/signup';
import Dashboard from './screens/dashboard/dashboard';
import store from './redux/store';
import { Provider } from 'react-redux';
import { useEffect, useState } from 'react';
import LoadingScreen from './screens/loadingScreen';
import PublicProfilePage from './screens/dashboard/publicProfilePage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Zoom } from 'react-toastify';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#3d215b', // Set your primary color
      },
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            transition={Zoom}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
            className="react-toastify"
            bodyClassName="toast-body"
          />
          <Routes>
            <Route path="/" element={<LoadingScreen />} />
            <Route path="/home" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/profile/:username" element={<PublicProfilePage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
